import './About.css'
import apple from '../../assets/apple.png'
import android from '../../assets/android.png'

export default function About() {
    return (
        <div className={"aboutContainer"}>
            <p><strong>The goal</strong></p>
            <p>Let's make the world a better place for 1 minute every day at noon. The key to this is empathy. During this minute we ask everybody to</p>
            <ul>
                <li>Treat others the way that you think they want to be treated.</li>
                <li>Treat the planet in a sustainable way.</li>
            </ul>
            <p>Every day at noon let's practice this idea. We can do this in a few different ways. </p>
            <ul>
                <li>If you're interacting with another person, animal or life-form, treat him/her/it in a positive way or a way that you think they would want to be treated.</li>
                <li>If you're unable to do this directly then you can pray, meditate or focus your conciousness in a way to help remind yourself and others to practice this teaching in your community.</li>
                <li>If you're about to take off driving for work or to do an errand, take a pause for a minute.</li>
                <li>If you're about to engage in a consumerist activity such as order a new thing from amazon or order a steak from a restaraunt, take a pause for a minute.</li>
                <li>You are not limited to the items on this list. If you think you can treat others and the planet in a good way for this minute, please do so.</li>
            </ul>
            <p><strong>How does this work?</strong></p>
            <p>Every day, a few minutes before noon you will receive a notifcation reminding you that the Global empthy minute is beginning shortly. The notification comes through the mobile application.</p>
            <p><strong>The hope</strong></p>
            <p>Through using modern technology we can coordinate our efforts to practice this universal peaceful teaching and potentially make the world better at a specific point in time.</p>
            <p><strong>Why do I need this website?</strong></p>
            <p>The truth is you don't need this website to practice the golden rule at noon with everybody. This platform exists simply to get the word out and also coordinate folks to practice empathy at the same time every day.</p>
            <p><strong>The mobile app is coming soon.</strong></p>
            <div className="mobileLogoContainer">
                <img src={apple} />
                <img src={android}/>
            </div>
        </div>
    )
}