import './Splash.css'

export default function Splash() {
    return (
        <div className={"splashContainer"}>
            <h1 className={"splashTitle"}>
                Make the world a better place
            </h1>
            <h2 className={"splashSubtitle"}>
                for 1 minute every day at noon
            </h2>
        </div>
    )
}
