import './App.css';
import Splash from "./components/splash/Splash";
import {Header} from "./components/header/Header";
import GmrParticles from "./components/particles/GmrParticles";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import About from "./components/about/About";

function App() {


    return (
    <div className={"appContainer"}>
        <BrowserRouter>
        <GmrParticles />
        <Header />
            <Routes>
                <Route path="/" element={<Splash />} />
                <Route path="/about" element={<About />} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
