import './Header.css'
import {Link} from "react-router-dom";
import we from '../../assets/world_empathy.jpg'

export function Header() {
    return (
        <div className={"headerContainer"}>
            <div className={"goldenRuleHeader"}><img className={"world_empathy_logo"} src={we} /></div>
            <nav className={"navContainer"}>
                <Link className={"link"} to="/">Home</Link>
                <Link className={"link"} to="/about">About</Link>
                {/*<div>language</div>*/}
            </nav>
        </div>
    )
}